import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from "react-bootstrap/Image";
import Jumbotron from 'react-bootstrap/Jumbotron'
import {faTerminal} from '@fortawesome/free-solid-svg-icons';
import {faGithub, faLinkedin, faStackOverflow} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <Navbar id="#home" className="border-radius-top" bg="light" variant="light" sticky="top" expand="md" collapseOnSelect>
          <Navbar.Brand className="text-uppercase" href="#home">Darren Gorman</Navbar.Brand>
          <Navbar.Toggle/>
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Item>
                <Nav.Link href="#about" eventKey="about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="#contact" eventKey="contact">Contact</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Jumbotron className="bg-deep-red text-center text-white">
          <Container className="heading-text">
            <FontAwesomeIcon icon={faTerminal} size="10x" color="white"/>
            <h1 className="text-uppercase">Darren Gorman</h1>
            <h3>Software Developer &amp; ScrumMaster</h3>
          </Container>
        </Jumbotron>
        <Container id="about" className="heading-text justify-content-center">
          <h3>Hi, I'm Darren!</h3>
          <br/>
          <p>I'm a software craftsman with a passion for building high-performing applications.</p>
          <p>I've worked with a lot of really cool and talented people on some fantastic projects across banking, retail, media and automotive technology.</p>
          <p>I love to write beautiful server-side code, predominantly with <strong>Java</strong>.</p>
          <p>I'm a Scrum Alliance <strong>Certified ScrumMaster</strong> &amp; <strong>Certified Scrum Developer</strong>.</p>
          <Row>
            <Col className="text-right">
              <Image src="/csm.png"/>
            </Col>
            <Col className="text-left">
              <Image src="/csd.png"/>
            </Col>
          </Row>
        </Container>
        <Jumbotron id="contact" className="bg-deep-red text-center text-white">
          <Container>
            <Row>
              <Col sm={12} md={6} className="heading-text">
                <br/>
                <h3>Contact</h3>
                <br/>
                <Button className="btn-online" href="/cv.pdf" target="_blank" variant="outline-light">CV</Button>
              </Col>
              <Col sm={12} md={6} className="heading-text">
                <br/>
                <h3>Online</h3>
                <br/>
                <Row>
                  <Col className="text-right">
                    <Button className="btn-online" href="https://github.com/darrengormanuk" target="_blank" variant="outline-light">
                      <FontAwesomeIcon icon={faGithub}/>
                    </Button>
                  </Col>
                  <Col>
                    <Button className="btn-online" href="http://linkedin.com/in/darrengorman" target="_blank" variant="outline-light">
                      <FontAwesomeIcon icon={faLinkedin}/>
                    </Button>
                  </Col>
                  <Col className="text-left">
                    <Button className="btn-online" href="http://stackoverflow.com/users/69352/darrengorman" target="_blank" variant="outline-light">
                      <FontAwesomeIcon icon={faStackOverflow}/>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Container className="text-center">
          <p className="font-italic">This website was created using React &amp; Bootstrap with some Font Awesome styling, deployed onto AWS</p>
        </Container>
      </div>
    );
  }
}

export default App;
